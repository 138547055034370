import React from 'react';

import { SubHeading} from '../../components';
import { images,data } from '../../constants';

import './Sustainibility.css';

const GoalCard = ({goal: {imgUrl, title, subtitle}}) => (
  <div className='app__sdgs_goals-card'>
    <img src={imgUrl} alt="goal" />
    <div className='app__sdgs_goals-card_content'>
      <p className='p__cormorant' style={{color: '#44b31f'}}>{title}</p>
      <p className='p__cormorant'>{subtitle}</p>
    </div>
  </div>
)

const Sustainibility = () => (
  <div className='app__bg app__wrapper section__padding' id='sdgs'>
    <div className='app__wrapper_info'>
      <SubHeading title="Building a better tomorrow"/>
      <h1 className='headtext__cormorant'>Our Social Developement Goals</h1>
      <p className='p__opensans'>Scrap materials play a crucial role in creating an eco-friendly world by conserving natural resources, reducing energy consumption, and minimizing greenhouse gas emissions. Recycling scrap helps alleviate landfill burden and promotes environmental sustainability. By transforming waste into valuable resources, we can significantly reduce our environmental footprint and foster a healthier planet for future generations.</p>
      <div className='app__sdgs_goals'>
        {data.goals.map((goal) => < GoalCard goal={goal} key ={goal.title}/>)}
      </div>

    </div>
    <div className='app__wrapper_img'>
      <img src={images.sdg} alt="sdgs"/>
    </div>
  </div>
);

export default Sustainibility;
