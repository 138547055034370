import React, {useState} from 'react';

import { FiFacebook, FiLinkedin, FiPhone, FiMail } from 'react-icons/fi';
import { SubHeading } from '../../components';
import {images} from '../../constants';
import './Footer.css';
import { db } from "./firebase";
 

const Footer = () =>{
  const [name, setName ] = useState("")
  const [number, setNumber ] = useState("")
  const [subject, setSubject ] = useState("")
  const [message, setMessage ] = useState("")
 
  const getData = async(e) =>{
      e.preventDefault();
      db.collection("Enquiry").add({
        name: name,
        number: number,
        subject: subject,
        message: message,
      })
      .then(() =>{
        alert("Message Sent");
      })
      .catch((error) =>{
          alert(error.Message);
      })
    setName("");
    setNumber("");
    setSubject("");
    setMessage("");
  }
  return( 
   
    <div className='app__footer app__bg section__padding'>
      <div className='app__footer-form' id='enquire'>
        <SubHeading title={"Write To Us"}/>
        <form action="" id="contactForm" onSubmit={getData}>
              <input placeholder="Your name...." value={name} onChange={(e) => setName(e.target.value)} required/><br/>
  
              <input placeholder="Your phone number....." value={number} onChange={(e) => setNumber(e.target.value)} required /><br/>
  
              <input placeholder="Subject...." onChange={(e) => setSubject(e.target.value)} value={subject} /><br/>
            
              <textarea cols="30" rows="10" placeholder="Message" onChange={(e) => setMessage(e.target.value)} value={message}></textarea><br/>
  
              <button type="submit" className='custom__button'>Submit</button>
          </form>
      </div>
      <div className='app__footer-links'>
        <div className='app__footer-links_contact'>
          <h1 className='app__footer-headtext'>Contact Us</h1>
          <p className='p__opensans'>HB Traders, Choti Karila, Shashtri Ward, Sagar</p>
          <p className='p__opensans'><FiMail style={{marginRight: 10}}/>hbtraderssagar@gmail.com </p>
          <p className='p__opensans'><FiPhone style={{marginRight: 10}}/> +91 94-254-254-96</p>
          <p className='p__opensans'><FiPhone style={{marginRight: 10}}/> +91 73-891-966-35</p>
        </div>
        <div className='app__footer-links_logo'>
        <img src={images.recycle} alt="recycle" className='spoon__img'/>
          <img src={images.hbtraders}  alt="footer_logo" />
          
          <p className='p__opensans'>Unlock the hidden value in your scrap. Transforming junk into cash, effortlessly.</p>
          <div className='app__footer-links_icons'>
            <a href='https://www.facebook.com/sagar.scrapers' target='_blank'><FiFacebook /></a>
            <a href='https://www.linkedin.com/company/hb-traders-sagar/' target='_blank'><FiLinkedin/></a>
          </div>
        </div>
        <div className='app__footer-links_work'>
          <h1 className='app__footer-headtext'>Working Hours</h1>
          <p className='p__opensans'>Mon- Sat: 10:30 am - 6 pm </p>
        </div>
      </div>
    </div>
  )
} 

export default Footer;
