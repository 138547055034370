import React,{useState, useEffect} from 'react';

import { AboutUs, CoOwner, FindUs, Footer, Header, Intro, Prices,BusinessType, Sustainibility} from './container';
import { Navbar } from './components';
import './App.css';
import { BeatLoader } from 'react-spinners';


const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() =>{
      setLoading(false)
    },5000)
  }, [])
  

  return( 
    <div class='app'>
    { loading ? (<BeatLoader className='loader' size={25} color ={"#44b31f"} loading = {loading} />) :
      (
      <div id="root">
        <Navbar />
        <Header />
        <AboutUs />
        <Prices/>
        <BusinessType />
        <Sustainibility />
        <CoOwner />
        {/* <Intro /> */}
        <FindUs />
        <Footer />
      </div>
      )
    }
  </div>
  )
}

export default App;
