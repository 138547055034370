import React from 'react';

import {images} from '../../constants';

import './BusinessType.css';

const BusinessType = () => (
    <div className='app__businesstype  app__bg section__padding' id='business'>
    <div class="app__businesstype-overlay flex__center section__padding">
      <img src={images.hb} alt="g letter"></img>
    </div>
    <div className='app__businesstype-content flex__center'>
      <div className='app__businesstype-content_about'>
        <h1 className='headtext__cormorant'>Business to Business</h1>
        <img src={images.business} style={{width:'250px'}} ></img>
        <p className='p__opensans'>In today's rapidly evolving business landscape, sustainability isn't just a buzzword; it's a necessity. At HB Traders, we empower 
        businesses to transform their waste into wealth, turning environmental responsibility into a profitable venture. By partnering with us, companies can lead the 
        way to a cleaner, greener future while enhancing their bottom line. Our innovative recycling solutions and commitment to sustainability help businesses not only
        reduce their environmental footprint but also demonstrate their dedication to a sustainable world. Join us in pioneering a new era of responsible and profitable
        business practices.</p>
      </div>

      <div className='app__businesstype-content_knife flex__center'>
      </div>

      <div className='app__businesstype-content_history'>
        <h1 className='headtext__cormorant'>Business to Customer</h1>
        <img src={images.individual} style={{width:'250px'}}></img>
        <p className='p__opensans'>In an age where every action counts towards preserving our planet, individuals have the power to make a significant impact.
         At HB Traders, we help you transform your household waste into valuable resources, making sustainability an achievable and rewarding goal. By recycling
         with us, you can lead the way to a cleaner, greener future, reduce your environmental footprint, and contribute to a healthier planet for future generations.
         Join us in embracing responsible living and make a tangible difference in the world around you. Together, we can pioneer a new era of environmental
         stewardship and sustainable living.</p>
      </div>

    </div>
  </div>
);

export default BusinessType;
