import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyC0E5v_vN1kvHLtbpAFRFVnHgtQ_AuWl2w",

    authDomain: "hbtrader2-b23f2.firebaseapp.com",
  
    databaseURL: "https://hbtrader2-b23f2-default-rtdb.firebaseio.com",
  
    projectId: "hbtrader2-b23f2",
  
    storageBucket: "hbtrader2-b23f2.appspot.com",
  
    messagingSenderId: "251794129357",
  
    appId: "1:251794129357:web:f530b5a6ef15cee9acc484"
});

var db = firebaseApp.firestore();

export { db };