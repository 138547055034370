import React from 'react';

import {images} from '../../constants';

import './AboutUs.css';

const AboutUs = () => (
  <div className='app__aboutus app__bg flex__center section__padding' id='about'>

    <div className='app__aboutus-content flex__center'>
      <div className='app__aboutus-content_about'>
        <h1 className='headtext__cormorant'>Meet Our Founder</h1>
        <img src={images.founder} alt="img of hamid bhai" style={{width: 250, marginBottom: 20}} />
        <h2 className='heatext__cormorant' style={{color:'var(--color-white)'}}> Haji Adbul Hamid(1923-2016)</h2>
        <p className='p__opensans'>
        Haji Abdul Hamid, who lived by the principle "work is worship," founded Sagar Scrapers with unwavering dedication and integrity. His tireless efforts and ethical conduct transformed the company into a respected name in the scrap dealing industry. Known as "Hamid Bhai," he inspired many with his commitment to hard work and community service.
        <br/><br/>
In 2017, to honor his memory, Sagar Scrapers was renamed HB Traders, with "HB" standing for "Hamid Bhai." This renaming celebrates his lasting impact and the values he instilled in the business, which continue to guide HB Traders today.</p>
      </div>

      <div className='app__aboutus-content_knife flex__center'>
        <img src={images.gogreen} alt="about_knife"/>
      </div>

      <div className='app__aboutus-content_history'>
        <h1 className='headtext__cormorant' style={{marginBottom:20}}>Our history</h1>
        <img src={images.hbtraders} alt="about_recycle" style={{width:'350px'}}/>
        <p className='p__opensans'>HB Traders, originally known as Sagar Scrapers, was founded by Haji Abdul Hamid, a man deeply committed to the principle that "work is worship." Haji Abdul Hamid's dedication to hard work, integrity, and community service laid the foundation for the company in the scrap dealing industry. His efforts transformed Sagar Scrapers into a respected and successful business.
<br /><br />
In 2017, to honor Haji Abdul Hamid's memory and his profound impact on the business and the community, Sagar Scrapers was renamed HB Traders. The "HB" stands for "Hamid Bhai," reflecting the deep respect and admiration for Haji Abdul Hamid. Under this new name, HB Traders continues to uphold the values and principles established by its founder, remaining committed to hard work, ethical conduct, and community service.
<br /><br />
Today, HB Traders is not only a thriving scrap dealing company but also a beacon of inspiration, carrying forward Haji Abdul Hamid's legacy of dedication and excellence.
</p>
      </div>

    </div>
  </div>
);

export default AboutUs;
