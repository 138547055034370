import React from 'react';
import {FiPhone,FiMail} from 'react-icons/fi'

import { SubHeading} from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className='app__wrapper section__padding' id='contact' style={{background:'var(--color-black)'}}>
    <div className='app__wrapper_info'>
      <SubHeading title="Contact"/>
      <h1 className='headtext__cormorant' style={{marginBottom: '3rem'}}>Find Us</h1>
      <div className='app__wrapper-content'>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3649.021015638278!2d78.71682977620246!3d23.85338747860033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3978d7005d9d9ac5%3A0x198c140036eab09!2sHB%20Traders!5e0!3m2!1sen!2sin!4v1718117904504!5m2!1sen!2sin" width="600" height="450" style={{border:0, filter: 'invert(90%)', marginBottom: 30, filter: 'hue-rotate(180deg)',display:'flex'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <p className='p__opensans'>HB Traders, Choti Karila, Shashtri Ward, Sagar</p>
        <p className='p__opensans'><FiMail className='icons' />hbtraderssagar@gmail.com</p>
        <p className='p__opensans'><FiPhone className='icons'/> +91 94-254-254-96</p>
        <p className='p__opensans'><FiPhone className='icons'/> +91 73-891-966-35</p>
        <p className='p__opensans' style={{color:'#44b31f', margin:'2rem 0', fontSize: '2em'}}>Opening Hours</p>
        <p className='p__opensans'>Mon- Sat: 10:30 am - 6 pm </p>
        <a href='#enquire'><button className='custom__button btn_1'>Visit Us</button></a>
      </div>
    </div>

    <div className='app__wrapper_img'>
      <img src={images.findus} alt="findus" />
    </div>

  </div>
);

export default FindUs;
