import images from './images';

const papers = [
  {
    title: 'Newspaper',
    price: '₹10',
    tags: 'per kg',
  },
  {
    title: 'Books and Cartons',
    price: '₹9',
    tags: 'per kg',
  },
  {
    title: 'Cartons',
    price: '₹5',
    tags: 'per kg',
  },
  {
    title: 'Soft Plastic',
    price: '₹12',
    tags: 'per kg',
  },
  {
    title: 'Hard Plastic',
    price: '₹3',
    tags: 'per kg',
  },
  {
    title: 'Polythene(mix)',
    price: '₹8',
    tags: 'per kg',
  },
];

const metals = [
  {
    title: 'Iron',
    price: '₹26',
    tags: 'per kg',
  },
  {
    title: 'Tin',
    price: '₹20',
    tags: 'per kg',
  },
  {
    title: 'Aluminium',
    price: '₹100',
    tags: 'per kg',
  },
  {
    title: 'Copper',
    price: '₹400',
    tags: 'per kg',
  },
  {
    title: 'Steel',
    price: '₹45',
    tags: 'per kg',
  },
];

const goals = [
  {
    imgUrl: images.award02,
    title: 'Goal 3',
    subtitle: 'Good Health and Well being',
  },
  {
    imgUrl: images.award01,
    title: 'Goal 6',
    subtitle: 'Clean Water and Sanitation',
  },
  {
    imgUrl: images.award05,
    title: 'Goal 11',
    subtitle: 'Sustainable Cities and Development',
  },
  {
    imgUrl: images.award03,
    title: 'Goal 15',
    subtitle: 'Life on Land',
  },
];

export default { papers, metals, goals };
