import React from 'react';

import {images} from '../../constants';
import { SubHeading} from '../../components';

import './CoOwner.css';

const Chef = () => (
  <div className='app__wrapper section__padding' id='co-owner'>
    <div className='app__wrapper_img app__wrapper_img-reverse'>
      <img src={images.amaan} alt="chef" />
    </div>
  
    <div className='app__wrapper_info'>
      <SubHeading title="Co-owner's Word"/>
      <h1 className='headtext__cormorant'>What we believe in</h1>
      <div className='app__chef-content'>
        <div className='app__chef-content_quote'>
          <img src={images.quote} alt="quote" />
          <p className='p__opensans'>

As co-founder of HB Traders, I believe in the critical role of recycling in keeping our beloved city, Sagar, clean. With growing urban challenges, the need of the hour is clear: embrace recycling and responsible waste disposal. By efficiently managing scrap and reducing landfill waste, we can conserve resources and minimize pollution.
<br/>
At HB Traders, we are committed to leading this change by supporting the circular economy and turning waste into valuable resources. Join us in our mission to make Sagar cleaner and more sustainable for future generations. Together, we can create a healthier environment for all.</p>
        </div>
      </div>
      <div className='app__chef-sign'>
        <p className='p__opensans'>Mohammad Amaan</p>
        <p className='p__opensans'>Co-owner</p>
      </div>
    </div>
  </div>

);

export default Chef;
