import React from 'react';

import { SubHeading, MenuItem} from '../../components';
import { images,data } from '../../constants';

import './Prices.css';

const SpecialMenu = () => (
  <div className='app__specialMenu flex__center section__padding' id='menu'>
    <div className='app__specialMenu-title'>
      <SubHeading title="Sell scrap at best rates"/>
      <h1 className='headtext__cormorant'>Prices for Scrap</h1><br/>
      <p className='p__opensans'>Note: For Bulk scrap (Commercial) prices may vary. Contact us to know more</p>
    </div>
    
    <div className='app__specialMenu-menu'>
      <div className='app__specialMenu-menu_paper flex__center'>
          <p className='app__specialMenu-menu_heading'>Paper and Plastic</p>
          <div className='app__specialMenu_menu_items'>
            {data.papers.map((paper, index) =>(
              <MenuItem key={paper.title + index} title={paper.title} price={paper.price} tags={paper.tags}/>
              ))
            }
          </div>
      </div>

      <div className='app__specialMenu-menu_img'>
        <img src={images.scrapscale} alt="menu" />
      </div>

      <div className='app__specialMenu-menu_metals flex__center'>
        <p className='app__specialMenu-menu_heading'>Metals</p>
        <div className='app__specialMenu_menu_items'>
            {data.metals.map((metal, index) =>(
                <MenuItem key={metal.title + index} title={metal.title} price={metal.price} tags={metal.tags}/>
              ))
            }
        </div>
      </div>
    </div>
      <div style={{marginTop: 15}}>
      </div>
  </div>
);

export default SpecialMenu;
