import React from 'react';

import { SubHeading} from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className='app__header app__wrapper section__padding' id='home'>
    <div className='app__wrapper_info'>
      <SubHeading title="Building a new tommorow"/>
      <h1 className='app__header-h1'>Trade Scrap for money</h1>
    <p className='p__opensans' style={{ margin: '2rem 0' }}>HB Traders, a place where you can turn your trash into cash  – where every scrap finds its value!<br />
    HB Traders is a B2B and a B2C Scrap Trading Firm</p>
    <a href='#menu'><button type="button" className='custom__button'>Explore Menu</button></a>
    </div>
    <div className='app__wrapper_img'>
      <img src={images.headerImg} alt="header img"></img>
    </div>
  </div>
);

export default Header;
