import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineClose } from 'react-icons/md';

import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return(
    <nav className = 'app__navbar'>
    <div className='app__navbar-logo'>
      <img src={images.recycle} alt="recycle img"  style={{width:'36px'}}/>
      <img src={ images.hbtraders } alt="app logo"/>
    </div>
    <ul className='app__navbar-links'>
      <li className='p__opensans'><a href="#home">Home</a></li>
      <li className='p__opensans'><a href="#about">About</a></li>
      <li className='p__opensans'><a href="#menu">Prices</a></li>
      <li className='p__opensans'><a href="#business">B2B | B2C</a></li>
      <li className='p__opensans'><a href="#sdgs">SDGs</a></li>
    </ul>
    <div className='app__navbar-login'>
      <a href="#enquire" className="p__opensans">Enquire</a>
      <div />
      <a href="#contact" className='p__opensans'>Contact Us</a>
    </div>
    <div className='app__navbar-smallscreen'>
      <GiHamburgerMenu color='#fff' fontSize={27} onClick={() => setToggleMenu(true)}/>

      {toggleMenu && (
        <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
          <MdOutlineClose fontSize ={27} className='overlay__close' onClick={() => setToggleMenu(false)}/>
          <ul className='app__navbar-smallscreen_links'>
            <li className='p__opensans'><a href="#home">Home</a></li>
            <li className='p__opensans'><a href="#about">About</a></li>
            <li className='p__opensans'><a href="#menu">Prices</a></li>
            <li className='p__opensans'><a href="#sdgs">SDGs</a></li>
            <li className='p__opensans'><a href="#contact">Contact</a></li>
          </ul>
        </div>
      )}
      
      
    </div>
  </nav>
  )
}

export default Navbar;
