import bg from '../assets/bg.png';
import overlaybg from '../assets/overlaybg.png';
import findus from '../assets/hb_shop.jpeg';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import quote from '../assets/quote.png';
import hbtraders from '../assets/hb_traders.png';
import headerImg from '../assets/trash_heading.jpg';
import recycle from '../assets/recycle-symbol(1).png';
import hb from '../assets/H B.png';
import aboutus from '../assets/about_us.jpg'
import gogreen from '../assets/image_gogreen.png'
import scrapscale from '../assets/scrapescale.png'
import business from '../assets/business.png'
import individual from '../assets/individual.png'
import sdg from '../assets/sdg.png'
import amaan from '../assets/amaan.jpeg'
import founder from '../assets/founder.png'

export default {
  bg,
  overlaybg,
  findus,
  award01,
  award02,
  award03,
  award05,
  quote,
  hbtraders,
  headerImg,
  recycle,
  hb,
  aboutus,
  gogreen,
  scrapscale,
  business,
  individual,
  sdg,
  founder,
  amaan,
};
